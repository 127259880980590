import React, { useContext, useEffect } from 'react';
import { AppContext } from './context/AppContext';
import ReturnHome from './ReturnHome';

function SetTeamsMain(props) {
    const { FLAGS, teams, setTeams } = useContext(AppContext);
    if (FLAGS.debug === true) {
        console.log("Debut Mode");
    }
    
    useEffect(() => {
        // every time I go to the main page, close the popup
        props.hideHamburger();
    }, [props])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTeams(prevStruct => ({
            ...prevStruct,
            [name]: value
        }));
    };

    return (
        <div>
            <h1>Game Info</h1>
            <table><tbody>
                <tr><td></td><td>Home Team</td></tr>
                <tr><td>Display Name</td>
                    <td><input type='text' onInput={handleChange} name='homeShortName' value={teams.homeShortName} /></td></tr>
                <tr><td>Age & Division</td>
                    <td><input type='text' onInput={handleChange} name='homeAgeDivision' value={teams.homeAgeDivision} /></td></tr>
                <tr><td></td><td>Visitor Team</td></tr>
                <tr><td>Display Name</td>
                    <td><input type='text' onInput={handleChange} name='visitorShortName' value={teams.visitorShortName} /></td></tr>
                <tr><td>Age & Division</td>
                    <td><input type='text' onInput={handleChange} name='visitorAgeDivision' value={teams.visitorAgeDivision} /></td></tr>

                {/* <tr><td>FullName</td></tr> */}
                {/* <tr><td>Colour</td><td>default</td><td>default</td></tr> */}
            </tbody></table>
            <ReturnHome />
            {/* Cancel not implemented */}
        </div>
    );
}

export default SetTeamsMain;