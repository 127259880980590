import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from './context/AppContext';
import ReturnHome from './ReturnHome';

function SettingsMain(props) {
    const { FLAGS, setFlags, theme, setTheme } = useContext(AppContext);

    const toggleTheme = () => {
        console.log('old Theme was  ' + theme)

        if (theme === 'default') {
            setTheme('alt-theme')
        }
        else {
            setTheme('default')
        }
    };


    useEffect(() => {
        // every time I go to the main page, close the popup
        props.hideHamburger();
    }, [props])

    function setTheTheme(themeName) {
        console.log(themeName)
    }

    return (
        <div>
            <h1>Settings Page</h1>
            <h3>Current Theme:</h3>
            <ul>
                <li onClick={() => setTheTheme('default')}>Default</li>
                {/* <li onClick={() => setTheme('retro')}>Retro</li> */}
                <li onClick={() => setTheTheme('light')}>Light</li>
                <li onClick={() => setTheTheme('dark')}>Dark</li></ul>
            <div>
                <button onClick={toggleTheme}>Toggle Theme</button>
                {/* Your app content */}
            </div>


            These flags are for testing/development purposes, not for general use <br />
            <table><tbody>
                {Object.keys(FLAGS).map(key => (
                    <tr key={key} onClick={
                        () => setFlags(prev => ({
                            ...prev,
                            [key]: !FLAGS[key]
                        }))}>
                        <td>{key}</td><td>{FLAGS[key] ? 'True' : 'False'}</td></tr>
                ))}
            </tbody></table>
            <ReturnHome />
        </div>
    );
}

export default SettingsMain;