import React, { useContext, useEffect } from 'react';
import { AppContext } from './context/AppContext';
import recordEvent from './helperFunctions';
import { useNavigate } from 'react-router-dom';

function GoaliesMain(props) {
    const { FLAGS, timer, events, setEvents,
        period, goalies, setGoalies } = useContext(AppContext);

    useEffect(() => {
        // every time I go to the main page, close the popup
        props.hideHamburger();
    }, [props])

    const navigate = useNavigate();

    function saveGoalies() {
        if (FLAGS.debug === true){
        console.log('truying to saveGoalies')}
        recordEvent(timer, period, events, setEvents,
            'SetGoalies '
            + 'Home[' + goalies.home + ']'
            + 'Visitor[' + goalies.visitor + ']'
        );
        // need to call the event to save the goalies
        navigate('/');
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setGoalies(prevStruct => ({
            ...prevStruct,
            [name]: value
        }));
    };
    function pullGoalie(name) {
        console.log('Goalie Pulled: ' + name)
        setGoalies(prevStruct => ({
            ...prevStruct,
            [name]: 'Pulled'
        }));

    }
    return (
        <div>
            <h1>Goalies</h1>
            <div>
                <table><tbody><tr><td>Home Goalie</td><td>Visitor Goalie</td></tr>
                    <tr><td><input type='text' onInput={handleChange} name='home' value={goalies.home} /></td>
                    {/* I should fix this so its' a bit mroe like setting the team 
                    {goalies.homeGoalie}</td> */}
                        <td><input type='text' onInput={handleChange} name='visitor' value={goalies.visitor} /></td></tr>
                    <tr><td><div onClick={() => pullGoalie('home')}>Pull</div></td>
                        <td onClick={() => pullGoalie('visitor')}>Pull</td></tr>
                </tbody></table>
            </div>
            <button className='save' onClick={saveGoalies}>Save Goalies</button>
        </div>
    );
}

export default GoaliesMain;