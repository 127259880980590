import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import { AppContext } from './context/AppContext';


function fullShort(timer) {
    // if (timer < 60) {
    //     return formatSeconds(timer, 1);
    // } else {
    return formatSeconds(timer, 0);
    // }
}

function formatSeconds(seconds, decimalPlaces = 0) {

    // show different times depending on "stuff"
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Format minutes and seconds with leading zeros
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    // If decimal places are required, calculate the fractional part
    if (decimalPlaces > 0) {
        // const fractionalSeconds = (remainingSeconds - Math.floor(remainingSeconds)).toFixed(decimalPlaces);
        // return `${formattedMinutes}:${formattedSeconds}${fractionalSeconds.substring(1)}`;
        const fractionalSeconds = Math.floor((seconds - Math.floor(seconds)) * (10 ** decimalPlaces));
        const formattedfractionalSeconds = String(fractionalSeconds).padStart(decimalPlaces, '0');


        // \?).toFixed(decimalPlaces);
        return `${formattedMinutes}:${formattedSeconds}.${formattedfractionalSeconds}`;
    } else {
        return `${formattedMinutes}:${formattedSeconds}`;
    }
}



function DigitalClock(props) {
    const { FLAGS, timer, setTimer, period } = useContext(AppContext);

    function addTime(e) {
        setTimer(prev => (prev + Number(e.target.value)));
    };
    function subtractTime(e) {
        setTimer(prev => Math.max(prev - Number(e.target.value), 0));
    }
    const navigate = useNavigate();

    return (
        <div>
            <table onClick={() => navigate('/edittime')}><tbody><tr><th>Time</th><th>&nbsp; </th><th>Period</th></tr>
                <tr id='gameTime' className='gameTime clockTable digitalNumber' >
                    <td className='gameTime '>{fullShort(timer)}</td><td className="blankSpace"></td>
                    <td>{period}</td><td className="blankSpace"></td>
                   {props.showMicroAdjust ? 
                    (<td onClick={(event) => event.stopPropagation()} className='blankSpace'>{FLAGS.clockMainAdjust ?
                        (<div className='clockMicroAdjust'><button className='clockMicroAdjust' onClick={addTime} value='1' >+</button>
                            <button className='clockMicroAdjust' onClick={subtractTime} value='1' >-</button></div>) : (<div></div>)}</td>)
                            :<div></div> } 
                </tr></tbody></table>
        </div>);
}

export default DigitalClock;